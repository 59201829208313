import { all, fork } from 'redux-saga/effects';
import auth from './auth/sagas';

function* rootSagaGenerator() {
  yield all(
    [
      fork( auth )
    ]
  );
}

export default rootSagaGenerator;
