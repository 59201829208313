import { combineReducers } from 'redux';
import { reducer as voxeet } from '@voxeet/react-components';
import auth from './auth/reducer';
import chat from './chat/reducer';

export default ( injectedReducers ) => combineReducers( {
  auth,
  chat,
  voxeet,
  ...injectedReducers,
} );
