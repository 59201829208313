import Cookies from 'js-cookie';
import { all, call, put } from 'redux-saga/effects';

// Utils //
import history from '../../../utils/history';
import fetch from '../../../utils/fetch';

// Types //
import { LOGIN } from '../types';

function* loginRequestGenerator( { conferenceAlias, data: { name } } ) {
  try {
    const {
      data: { token, user },
    } = yield call( fetch, 'post', '/auth.php', {
      name,
      conferenceAlias
    } );

    Cookies.set(
      'user',
      JSON.stringify( user ),
      { expires: 7, path: `/${conferenceAlias}` }
    );

    Cookies.set(
      'streamToken',
      token,
      { expires: 7, path: `/${conferenceAlias}` }
    );

    yield all(
      [
        put( {
          type: LOGIN.SUCCESS,
          user,
          token,
        } ),
        call( history.push, `/${conferenceAlias}/call` ),
      ]
    );
  } catch ( error ) {
    yield put(
      {
        type: LOGIN.ERROR,
        error,
      }
    );
  }
}

export default loginRequestGenerator;
