import React, { Component } from 'react';
import styled from 'styled-components';

// Assets //
import BackgroundImg from '../assets/flower-background.jpg';

// Components //
import Text from '../components/Text';

const Root = styled.div`
  display: flex;
  flex: 1;
  background-image: url(${BackgroundImg});
  background-size: cover;
  height: 100vh;
`;

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${( { theme } ) => theme.colorUtils.fade( theme.color.background, 0.88 )};
`;

const Container = styled.div`
  max-width: 1280px;
  width: 100%;
  padding: 0px 24px;
  z-index: 1;
  margin-top: 100px;
`;

const Header = styled.div`
  display: flex;
  max-width: 1280px;
  width: 100%;
  margin: 0 auto;
  padding: 80px 24px 40px 24px;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
`;

class Thanks extends Component {
  get conferenceAlias() {
    return this.props.match.params.conferenceAlias;
  }

  render() {
    console.log( 'Here @ thanks' );

    return (
      <Root>
        <Container>
          <Overlay />
          <Header />
          <Text>Thanks!</Text>
        </Container>
      </Root>
    );
  }
}

export default Thanks;
