import React, { Component } from 'react';
import styled from 'styled-components';
import worksthingChime from '../sounds/worksthing-chime.mp3';

// Components //
import Text from '../components/Text';

const Root = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  flex: 1;
  & > ${Text} {
    opacity: .08;
    margin-top: 16px;
    text-transform: uppercase;
  }
`;

class LoadingScreen extends Component {
  static audio = null;
  static mountTime;

  get conferenceAlias() {
    return this.props.match.params.conferenceAlias;
  }

  async componentDidMount() {
    console.log( 'componentDidUpdate' );

    LoadingScreen.mountTime = new Date().getTime();

    if ( LoadingScreen.audio ) {
      LoadingScreen.audio.pause();
    }

    LoadingScreen.audio = new Audio( worksthingChime );

    LoadingScreen.audio.addEventListener( 'canplaythrough', ( _e ) => {
      LoadingScreen.audio.play().catch( ( e ) => {
        console.error( 'Could not play the sound', e.message );
      } );
    } );
  }

  componentWillUnmount() {
    const newTime = new Date().getTime();

    if ( newTime - LoadingScreen.mountTime < 500 ) {
      if ( LoadingScreen.audio ) {
        LoadingScreen.audio.pause();
      }
    }
  }

  render() {
    return (
      <Root>
        <Text size={16} weight='500'>
          Loading...
        </Text>
      </Root>
    );
  }
}

export default LoadingScreen;
