import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Field, Formik } from 'formik';
import * as Yup from 'yup';
import Button from '../components/Button';
import Input from '../components/Input';

const Root = styled.form`
  margin-top: 24px;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  & button {
    margin-top: 16px;
  }
`;

class LoginForm extends Component {
  get initialValues() {
    return {
      name: '',
    };
  }

  renderForm = ( form ) => {
    const html = (
      <Root onSubmit={form.handleSubmit}>
        <Field name='name' component={Input} placeholder='Your Name' />
        <Button label='Join Call' type='submit' />
      </Root> );

    return html;
  };

  render() {
    const { onSubmit } = this.props;
    const validationSchema = Yup.object().shape( {
      name: Yup.string().required(),
    } );

    return (
      <Formik
        children={this.renderForm}
        initialValues={this.initialValues}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
      />
    );
  }
}

LoginForm.propTypes = {
  conferenceAlias: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
};

export default LoginForm;
