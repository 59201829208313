import React from 'react';
import { ThemeProvider } from 'styled-components';
// import { Provider } from '@voxeet/react-redux-5.1.1';
import { VoxeetProvider } from '@voxeet/react-components';

// Router //
import { Router, Switch, Route } from 'react-router-dom';
import history from './utils/history';

// Containers //
import AuthedRoute from './containers/AuthedRoute';

// Styles //
import theme from './styles/theme';
import GlobalStyles from './styles/global';
import '@voxeet/react-components/dist/voxeet-react-components.css';

// Screens //
import Conference from './screens/Conference/Conference';
import Login from './screens/Login';
import Thanks from './screens/Thanks';

// Redux //
import createStore from './data/createStore';

const store = createStore();

function App() {
  return (
    <div>
      <ThemeProvider theme={theme}>
        <VoxeetProvider store={store}>
          <Router history={history}>
            <>
              <Switch>
                <AuthedRoute path='/:conferenceAlias/call' component={Conference} />
                <AuthedRoute path='/:conferenceAlias/thanks' component={Thanks} />
                <Route path='/:conferenceAlias' component={Login} />
              </Switch>
              <GlobalStyles />
            </>
          </Router>
        </VoxeetProvider>
      </ThemeProvider>
    </div>
  );
}

export default App;
