import React from 'react';
import { connect } from '@voxeet/react-redux-5.1.1';
import { Redirect, Route } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';

// Redux //
import { makeSelectIsAuthed } from '../data/auth/selectors';

import Text from '../components/Text';

const Thing = () => {
  console.log( 'Thing' );

  return ( <Text></Text> );
};

const AuthedRoute = ( { component: Component, isAuthed, loading, _queueSnackbar, ...rest } ) => {
  const html = (
    <Route
      {...rest}

      render={ ( props ) => {
        if ( loading ) {
          return <Thing />;
        }

        if ( !isAuthed ) {
          return (
            <Redirect to={ { pathname: `/${props.match.params.conferenceAlias}` } } />
          );
        }

        return <Component {...props} />;
      }}
    />
  );

  return html;
};

const mapStateToProps = createStructuredSelector( {
  isAuthed: makeSelectIsAuthed(),
} );

export default connect( mapStateToProps )( AuthedRoute );
