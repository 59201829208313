import VoxeetSdk from '@voxeet/voxeet-web-sdk';
import React, { Component } from 'react';
import { ConferenceRoom } from '@voxeet/react-components';
import { createStructuredSelector } from 'reselect';
import { connect } from '@voxeet/react-redux-5.1.1';

import voxeet from '../../data/voxeet';
import ActionButtons from '../../components/ActionButtons';

// Redux //
import { makeSelectCurrentUser } from '../../data/auth/selectors';

// Screens //
import LoadingScreen from '../LoadingScreen';
import AttendeesChat from './containers/AttendeesChat';

class Conference extends Component {
  handleOnConnect = () => {
    console.log( 'Participant connected' );

    VoxeetSdk.conference.audioProcessing(
      VoxeetSdk.session.participant, {
        send: { autoProcessing: true }
      }
    ).then( () => {
      console.log( 'Enabled audioProcessing for participant', VoxeetSdk.session.participant );
    } );
  };

  handleOnLeave = () => {
    const { match } = this.props;

    console.log( 'Participant disconnected' );
    this.props.history.push( `/${match.params.conferenceAlias}/thanks` );
  };

  get settings() {
    const { user } = this.props;

    let userInfo = {};

    if ( user ) {
      userInfo = {
        ...user,
        externalId: user.id,
        avatarUrl: user.image,
      };
    }

    VoxeetSdk.conference.on( 'participantAdded', ( newUser ) => {
      console.log( 'new user arrived', newUser );
    } );

    return {
      consumerKey: process.env.REACT_APP_VOX_KEY,
      consumerSecret: process.env.REACT_APP_VOX_SECRET,

      constraints: {
        audio: true,
        video: true
      },

      videoRatio: {
        width: 640,
        height: 480
      },

      videoCodec: 'H264',
      userInfo,
      sdk: voxeet.instance
    };
  }

  render() {
    const { match } = this.props;

    return (
      <ConferenceRoom
        loadingScreen={LoadingScreen}
        attendeesChat={AttendeesChat}
        actionsButtons={ActionButtons}
        isWidget={false}
        autoJoin={true}
        logo={'/loadingLogo.png?m=123'}
        kickOnHangUp
        dolbyVoice={true}
        preConfig={false}
        maxVideoForwarding={4}
        handleOnLeave={this.handleOnLeave}
        handleOnConnect={this.handleOnConnect}
        liveRecordingEnabled={false}
        {...this.settings}
        conferenceAlias={match.params.conferenceAlias}
      />
    );
  }
}

const mapStateToProps = createStructuredSelector( {
  user: makeSelectCurrentUser(),
} );

export default connect( mapStateToProps )( Conference );
