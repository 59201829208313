import { all, takeEvery } from 'redux-saga/effects';

// Sagas //
import loginRequest from './loginRequest';

// Types //
import { LOGIN } from '../types';

function* sagaGenerator() {
  yield all( [ takeEvery( LOGIN.REQUEST, loginRequest ) ] );
}

export default sagaGenerator;
