import React, { Component } from 'react';
import styled from 'styled-components';
import { Redirect } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import { connect } from '@voxeet/react-redux-5.1.1';

// Redux //
import { makeSelectIsAuthed, makeSelectCurrentUser } from '../data/auth/selectors';
import { loginRequest } from '../data/auth/actions';

// Assets //
import BackgroundImg from '../assets/flower-background.jpg';

// Forms //
import LoginForm from '../forms/LoginForm';

// Components //
import Text from '../components/Text';
import Button from '../components/Button';

const Root = styled.div`
  display: flex;
  flex: 1;
  background-image: url(${BackgroundImg});
  background-size: cover;
  height: 100vh;
`;

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${( { theme } ) => theme.colorUtils.fade( theme.color.background, 0.88 )};
`;

const Container = styled.div`
  max-width: 1280px;
  width: 100%;
  padding: 0px 24px;
  z-index: 1;
  margin-top: 100px;
`;

const Title = styled( Text )`
  margin-top: 16px;
  text-transform: capitalize;
`;

const Avatar = styled.div`
  width: 160px;
  height: 160px;
  border-radius: 50%;
  background-image: ${( { src } ) => `url(${src})`};
  background-size: cover;
  margin-bottom: 16px;
`;

const Header = styled.div`
  display: flex;
  max-width: 1280px;
  width: 100%;
  margin: 0 auto;
  padding: 80px 24px 40px 24px;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
`;

const CallBtn = styled( Button )`
  margin-top: 32px;
`;

class Login extends Component {
  get conferenceAlias() {
    return this.props.match.params.conferenceAlias;
  }

  get name() {
    const { user } = this.props;
    return user.name.replace( /_/g, ' ' );
  }

  handleLogin = ( val ) => {
    const loginRequestFunc = this.props.loginRequest;
    loginRequestFunc( val, this.conferenceAlias );
  };

  startCall = () => {
    const { history } = this.props;
    history.push( `/${this.conferenceAlias}/call` );
  }

  renderForm = () => (
    <Container>
      <Title size={40} weight='600'>
        Hello
      </Title>
      <Text size={16}>Enter your name to get started.</Text>
      <LoginForm conferenceAlias={this.conferenceAlias} onSubmit={this.handleLogin} />
    </Container>
  );

  renderWelcome = () => {
    const { user } = this.props;

    return (
      <Container>
        <Avatar src={user.image} />
        <Title size={32} weight='600'>
          Welcome back, {this.name}.
        </Title>
        <CallBtn label='Start Video Call' onClick={this.startCall} />
      </Container>
    );
  };

  render() {
    const { isAuthed } = this.props;

    if ( isAuthed ) {
      return (
        <Redirect to={ { pathname: `/${this.conferenceAlias}/call` } } />
      );
    } else {
      return (
        <Root>
          <Overlay />
          <Header />
          { isAuthed ? this.renderWelcome() : this.renderForm() }
        </Root>
      );
    }
  }
}

const mapStateToProps = createStructuredSelector( {
  isAuthed: makeSelectIsAuthed(),
  user: makeSelectCurrentUser()
} );


export default connect(
  mapStateToProps,
  { loginRequest },
)( Login );
