import Cookies from 'js-cookie';
import { LOGIN } from './types';

const init = {
  user: Cookies.get( 'user' ) && Cookies.get( 'user' ) != 'undefined' ? JSON.parse( Cookies.get( 'user' ) ) : undefined,
  streamToken: Cookies.get( 'streamToken' ),
  loading: false,
  error: false,
};

export default ( state = init, action ) => {
  switch ( action.type ) {
    case LOGIN.REQUEST:
      return { ...state, loading: true };

    case LOGIN.SUCCESS:
      return { ...state, loading: false, user: action.user, streamToken: action.token };

    default:
      return state;
  }
};
