export const LOGIN = {
  REQUEST: 'Login/REQUEST',
  SUCCESS: 'Login/SUCCESS',
  ERROR: 'Login/ERROR',
};

export const LOGOUT = {
  REQUEST: 'Logout/REQUEST',
  SUCCESS: 'Logout/SUCCESS',
  ERROR: 'Logout/ERROR',
};
