export default {
  trueblack: '#000000',
  black: '#000000',
  gray: '#111210',
  slate: '#232328',
  red: '#DC2C20',
  blue: '#32aee8',
  purple: '#8169a8',
  white: '#ffffff',
};
