import VoxeetSdk from '@voxeet/voxeet-web-sdk';

export default class Sdk {
  constructor() {
    throw new Error( 'Don\'t instantiate!' );
  }

  static create() {
    if ( !Sdk.instance ) {
      Sdk.instance = new VoxeetSdk();
    }

    return Sdk;
  }

  static destroy() {
    Sdk.instance = undefined;
  }

  static setSdk( sdk ) {
    Sdk.instance = sdk;
  }
}
